import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The 24th Colloquium of African Geology will be held at the United Nations
Economic Commission  Conference Center (UNECA) in Addis Ababa, Ethiopia from 8
to 14 January 2013  (`}<a parentName="p" {...{
        "href": "http://www.cag24.org.et/"
      }}>{`http://www.cag24.org.et/`}</a>{`). A session has been devoted to
`}<strong parentName="p">{`Regional geophysical mapping applied to exploration of the geology of Africa`}</strong>{`
with convenors Jörg Ebbing and Mark Jessel.  Over 20 oral presentations have
been received and accepted. We should all learn more about the  power of
airborne geophysics in exploring the geology of Africa! `}</p>
    <p>{`Contributions that highlight geological breakthroughs resulting from geophysical
mapping were  invited as were items that demonstrate correlations of geology
across national borders. It is now  more than 20 years since the African
Magnetic Mapping Project (AMMP) reached fruition and the  pursuit of geophysical
mapping through generous aid projects in Africa has never been more  active. The
picture below shows the final meeting of the AMMP project in Arusha, Tanzania in
1992. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      